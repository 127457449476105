import { forwardRef, useEffect, useMemo } from 'react';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import { SuspenseFallback } from '@/components/suspense-fallback/suspense-fallback';
import { getListingStageName } from '@/features/listing-stage/helpers/get-listing-stage-name';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { formatCalendarDate } from '@/helpers/format-date';
import { formatEmpty, formatNotApplicable } from '@/helpers/format-empty';
import { formatLocation } from '@/helpers/format-location';
import { mapValueToUSDFormat } from '@/helpers/map-value-to-usd-format';
import { openNewTab } from '@/helpers/open-new-tab';
import { useBoolean } from '@/hooks/use-boolean';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { isTableItemDraggingState } from '@/ui/table/draggable-table/draggable-table.state';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import type { IRow } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { UseCaseFitSelect } from '../use-case-fit/use-case-fit-select';
import type { TabStage } from '../use-stage-tabs-config';
import { useTabProjectListings } from '../use-tab-project-listings';

import { DealTypeSelect } from './deal-type-select';
import { ListingMenu } from './listing-menu';
import { getProjectListingsHeaders } from './project-listing-table-configuration';
import { Rating } from './ranking';
import type { ProjectCompaniesSort } from '@/api/v4/projects.api';
import { Link } from '@/ui/link/link';
import { theme } from '@/theme/setupTheme';
import { paths } from '@/routes/helpers/paths';

export const useProjectListingsTableConfiguration = (tab: TabStage) => {
  const isDragging = useRecoilValue(isTableItemDraggingState);
  const [isRowClickEnabled, setRowClickEnabled] = useBoolean(false);
  const { hasRequiredPermission } = useUserPermissions();
  const tabListings = useTabProjectListings(tab);
  const { listingStages } = useListingStages();

  const columns = getProjectListingsHeaders(
    tab.name,
    hasRequiredPermission(PERMISSION.VOTE_USE_CASE_FIT),
  );
  const headers = useTranslateHeaders<ProjectCompaniesSort>(
    columns,
    'projects',
  );

  useEffect(() => {
    if (isDragging) setRowClickEnabled.on();
  }, [isDragging, setRowClickEnabled]);

  const canGoToCompanyPage = hasRequiredPermission(
    PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN,
  );

  const rows: IRow[] = useMemo(
    () =>
      tabListings.map(listing => {
        const hqLocation = listing.organization.hqLocation
          ? {
              cityName: listing.organization.hqLocation.city?.name,
              regionName: listing.organization.hqLocation.region?.name,
              countryName: listing.organization.hqLocation.region?.name,
            }
          : null;
        return {
          id: listing.id,
          onClick: () => {
            if (isRowClickEnabled) {
              // needed to prevent redirection to Company Page
              // while the row is being dragged
              setRowClickEnabled.off();
              return;
            }
          },
          cells: [
            {
              value: (
                <Link
                  color={theme.colors.basics.black}
                  aria-disabled={
                    !canGoToCompanyPage || !listing.organization.id
                  }
                  to={paths.company({ companyId: listing.organization.id })}
                >
                  <Flexbox name="logo-container" gap="8px" alignItems="center">
                    <TableLogo
                      name={listing.organization.name ?? ''}
                      logoUrl={listing.organization.logoUrl ?? undefined}
                      disabled={!canGoToCompanyPage}
                    />
                    <Stack gap="3px">
                      <Flexbox
                        name="discovery-company-name"
                        alignItems="center"
                        gap="2px"
                      >
                        <CompanyName
                          disabled={!canGoToCompanyPage || isDragging}
                        >
                          {listing.organization.name}
                        </CompanyName>

                        {listing.organization.website ? (
                          <IconButton
                            icon="Globe"
                            variant="ghost"
                            color={colors.basics.black}
                            onClick={event => {
                              event.stopPropagation();
                              listing.organization.website &&
                                openNewTab(listing.organization.website);
                            }}
                          />
                        ) : null}
                      </Flexbox>
                      <Tooltip
                        disabled={!listing.organization.hqLocation}
                        content={formatEmpty(
                          hqLocation && formatLocation(hqLocation),
                        )}
                      >
                        <Location>
                          {formatEmpty(
                            hqLocation &&
                              formatLocation(hqLocation, {
                                compact: true,
                              }),
                          )}
                        </Location>
                      </Tooltip>
                    </Stack>
                  </Flexbox>
                </Link>
              ),
            },
            {
              value: formatNotApplicable(listing.organization.yearEstablished),
            },
            {
              value: (
                <Stack gap="3px">
                  {formatNotApplicable(listing.organization.fundingStage?.name)}
                  <LastRoundDate>
                    {formatCalendarDate(listing.organization.lastFundingAt)}
                  </LastRoundDate>
                </Stack>
              ),
            },
            {
              value: listing.organization.capitalRaised
                ? mapValueToUSDFormat(
                    listing.organization.capitalRaised.toString(),
                  )
                : formatNotApplicable(listing.organization.capitalRaised),
            },
            {
              value: <Rating ranking={listing.organization.averageRating} />,
            },
            {
              value: (
                <div onClick={event => event.stopPropagation()}>
                  <UseCaseFitSelect
                    useCaseFit={listing.useCaseFit ?? null}
                    useCaseVotes={listing.useCaseFitVotes}
                    projectListingId={listing.id}
                  />
                </div>
              ),
            },
            {
              value: (
                <DealTypeSelect
                  companyStage={listing.status}
                  listingId={listing.id}
                />
              ),
            },
            {
              value: formatEmpty(
                getListingStageName(listing.status, listingStages),
              ),
            },
            {
              value: (
                <SuspenseFallback>
                  <ListingMenu listing={listing} />
                </SuspenseFallback>
              ),
              hoverOnly: true,
              align: 'right',
              verticalAlign: 'middle',
              padding: '0',
            },
          ],
        };
      }),
    [tabListings, isRowClickEnabled],
  );

  return { headers, rows };
};

const TableLogo = styled(Logo)<{ disabled: boolean }>`
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

const CompanyName = styled('p', forwardRef)<{ disabled: boolean }>`
  max-width: 173px;
  ${({ theme }) => theme.mixins.ellipsis};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  tr:hover & {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`;

export const Location = styled(SmallText, forwardRef)`
  max-width: 173px;
  ${({ theme }) => theme.mixins.ellipsis};
`;

const LastRoundDate = styled(SmallText)`
  font-family: ${({ theme }) => theme.fonts.neue};
`;
