import { useCallback, useEffect, useMemo } from 'react';
import { styled } from 'goober';

import type {
  ProjectCompaniesSort,
  ProjectListing,
} from '@/api/v4/projects.api';
import { formatNotApplicable } from '@/helpers/format-empty';
import { openNewTab } from '@/helpers/open-new-tab';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import { Checkbox } from '@/ui/checkbox/checkbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import type { HeaderCellConfig, IRow } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

const rowPadding = {
  left: '30px',
  right: '0px',
};

export const useEnhanceProjectConfigTable = () => {
  const {
    projectListings,
    companyFilterSelected,
    setCompanyFilterSelectAll,
    toggleCompanyFilterSelected,
  } = useProjectListingSuggestionState();

  const companiesTableHeaderTemplate = useMemo<
    HeaderCellConfig<ProjectCompaniesSort>[]
  >(
    () => [
      {
        id: 'project-listing-suggestion-table-main-header',
        columnKey: 'headerCompanyName',
        width: '100%',
        sortKey: 'companyName',
        padding: '30px 0px 20px 30px',
        defaultSortDirection: 'asc',
        showCheckbox: true,
        isChecked: Boolean(companyFilterSelected.length),
        checkboxCount: companyFilterSelected.length,
        checkboxIcon:
          companyFilterSelected.length !== (projectListings || []).length
            ? 'CheckMarkMinus'
            : undefined,
        onCheckboxChange: isChecked => {
          if (!projectListings) return;
          setCompanyFilterSelectAll(isChecked);
        },
      },
    ],
    [companyFilterSelected.length, projectListings, setCompanyFilterSelectAll],
  );

  const headers = useTranslateHeaders(
    companiesTableHeaderTemplate,
    'projects',
    'enhanceConfigCompanyTable',
  );

  useEffect(() => {
    headers;
  }, [headers, companyFilterSelected]);

  const CompanyNameTableCell = ({ listing }: { listing: ProjectListing }) => {
    const handleSelectCompany = useCallback(async () => {
      toggleCompanyFilterSelected(listing.organization.id);
    }, [listing.organization.id]);

    const handleWebsiteButtonClicked = (url: string | null) => {
      if (url) openNewTab(url);
    };

    return (
      <Flexbox name="company-name-container" gap="8px" height="65px">
        <Stack gap="0px">
          <Flexbox
            id={`enhance-company-option-${listing.organization.id}`}
            name={`enhance-company-option-${listing.organization.id}`}
            alignItems="center"
          >
            <RowCheckbox
              id={listing.organization.id}
              label=""
              checked={companyFilterSelected.includes(listing.organization.id)}
              onChange={handleSelectCompany}
            />
            <Logo
              singleLetter
              name={listing.organization.name}
              logoUrl={listing.organization.logoUrl ?? undefined}
              bgColor={colors.accent.green.c3}
              border={
                listing.organization.logoUrl
                  ? `2px solid ${colors.gray.c3}`
                  : undefined
              }
            />
            <CompanyName>{listing.organization.name}</CompanyName>
            {!!listing.organization.website && (
              <Tooltip
                content={listing.organization.website}
                placement="top"
                maxWidth="fit-content"
              >
                <IconButton
                  icon="Globe"
                  variant="ghost"
                  color={colors.basics.black}
                  onClick={() =>
                    handleWebsiteButtonClicked(listing.organization.website)
                  }
                />
              </Tooltip>
            )}
          </Flexbox>
          <KeyOffering
            id={`enhance-company-option-${listing.organization.id}-keyoffering`}
            name={`enhance-company-option-${listing.organization.id}-keyoffering`}
            alignItems="center"
          >
            {formatNotApplicable(listing.organization.keyOffering)}
          </KeyOffering>
        </Stack>
      </Flexbox>
    );
  };

  const rows: IRow[] = useMemo(() => {
    if (!projectListings) return [];
    return projectListings.map(listing => {
      const row: IRow = {
        id: listing.organization.id,
        rowPadding,
        rowHeight: '95px',
        cells: [
          {
            value: <CompanyNameTableCell listing={listing} />,
          },
        ],
      };
      return row;
    });
  }, [projectListings, companyFilterSelected]);

  return { headers, rows };
};

const CompanyName = styled('div')`
  ${({ theme }) => theme.typography.enhance.tableCompanyName}
  margin-left: 10px;
`;

const KeyOffering = styled(Flexbox)`
  ${({ theme }) => theme.typography.enhance.tableSmallText}
  margin: 5px 40px;
`;

const RowCheckbox = styled(Checkbox)`
  & + div {
    margin-right: 16px;
  }
`;
