import { useState } from 'react';
import { useDrag } from 'react-dnd-cjs';
import { NativeTypes } from 'react-dnd-html5-backend-cjs';
import type { UseQueryResult } from '@tanstack/react-query';
import { styled } from 'goober';
import { isEmpty } from 'lodash-es';

import SvgFiles from '@/assets/widgets/empty-state-files.svg';
import { ActionPanelItem } from '@/features/action-panel/action-panel';
import { ActionPanelEmpty } from '@/features/action-panel/action-panel-empty';
import { withDraggableProvider } from '@/hoc/with-draggable-provider';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { FilesDragDrop } from '../../files-drag-drop/files-drag-drop';
import type {
  File,
  FileLinkDownloadHandler,
  FileScanning,
} from './file.interface';
import { FileCard } from './file-card';
import { UploadFileInput } from './upload-file-input';
import type { ShareFilePayload } from './edit-file';
import { withPermission } from '@/hoc/with-permission';
import { AVAILABLE_EXTENSIONS } from './constants';

interface FilesListContainerProps {
  files: File[];
  allowToChangeFilePrivacy?: boolean;
  emptyListLabel: string;
  onSubmit: (files: globalThis.File[]) => void;
  onClickDelete: (fileId: string, fileName: string) => void;
  onFileLinkDownload: FileLinkDownloadHandler;
  onShareFile: (payload: ShareFilePayload) => Promise<void>;
  useFileScanningStatus: (
    file: File,
  ) => UseQueryResult<FileScanning | undefined>;
}

const FilesDragDropWithPermission = withPermission(
  FilesDragDrop,
  PERMISSION.ADD_FILE,
);

const UploadFileInputWithPermission = withPermission(
  UploadFileInput,
  PERMISSION.ADD_FILE,
);

export const FilesListContainer = withDraggableProvider(
  ({
    files,
    emptyListLabel,
    onClickDelete,
    onSubmit,
    onFileLinkDownload,
    onShareFile,
    useFileScanningStatus,
    allowToChangeFilePrivacy = true,
  }: FilesListContainerProps) => {
    const [editedFileId, setEditedFileId] = useState<string | null>(null);
    const { hasRequiredPermission } = useUserPermissions();

    const canDrag = hasRequiredPermission(PERMISSION.ADD_FILE);

    const [{ draggingItems }] = useDrag({
      canDrag,
      item: {
        type: NativeTypes.FILE,
      },
      collect: monitor => ({
        draggingItems: monitor.getItem(),
      }),
    });

    return (
      <TabContainer name="action-panel-wrapper" direction="column" grow={1}>
        {Boolean(draggingItems) && (
          <FilesDragDropWithPermission onDrop={onSubmit} />
        )}
        <Flexbox
          name="action-panel-button-container"
          justify="right"
          alignItems="center"
          height="56px"
          padding="0 30px"
        >
          <UploadFileInputWithPermission
            onSubmit={onSubmit}
            acceptedFiles={AVAILABLE_EXTENSIONS}
          />
        </Flexbox>
        {isEmpty(files) ? (
          <ActionPanelEmpty image={SvgFiles} label={emptyListLabel}>
            <UploadFileInputWithPermission
              variant="primary"
              onSubmit={onSubmit}
              acceptedFiles={AVAILABLE_EXTENSIONS}
            />
          </ActionPanelEmpty>
        ) : (
          <ListContainer name="file-list-container" direction="column" grow={1}>
            {files.map(file => (
              <ActionPanelItem
                key={file.id}
                name="action-panel-item"
                alignItems="center"
                gap="16px"
                margin="0 12px"
                borderWidth="2px"
              >
                <Flexbox name="action-panel-text" gap="16px" grow={1}>
                  <FileCard
                    file={file}
                    editedFileId={editedFileId}
                    setEditedFileId={fileId => setEditedFileId(fileId)}
                    onClickDelete={onClickDelete.bind(null, file.id, file.name)}
                    onFileLinkDownload={onFileLinkDownload}
                    onShareFile={onShareFile}
                    allowToChangeFilePrivacy={allowToChangeFilePrivacy}
                    useFileScanningStatus={useFileScanningStatus}
                  />
                </Flexbox>
              </ActionPanelItem>
            ))}
          </ListContainer>
        )}
      </TabContainer>
    );
  },
);

const TabContainer = styled(Flexbox)`
  position: relative;
`;

const ListContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
  overflow: auto;
  ${({ theme }) => theme.mixins.scrollbar}
`;
