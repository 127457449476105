import { Stack } from '@/ui/line/line';
import { styled } from 'goober';
import { DraggableProvider } from '../../files-drag-drop/use-drag-and-drop';
import { useLoadCompaniesToProject } from './use-load-companies-to-project.mutation';
import { Alert } from '@/components/alert/alert';
import { LoadCompaniesToProjectDragDrop } from './load-companies-drag-and-drop';
import { useTranslation } from 'react-i18next';

const COMPANIES_BULK_IMPORT_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1Tq1rWFf4Ag88mxmAi3WtkiZaUJPcGgYc/edit?usp=sharing&ouid=107451855958666558457&rtpof=true&sd=true';

export const LoadCompaniesToProject = ({
  projectId,
}: {
  projectId: string;
}) => {
  const { t } = useTranslation('projects');
  const { mutateAsync, isLoading, data } = useLoadCompaniesToProject(projectId);

  const handleDroppedFiles = async (files: globalThis.File[]) => {
    await mutateAsync({
      file: files[0],
      projectId,
    });
  };

  return (
    <Stack gap="12px">
      {COMPANIES_BULK_IMPORT_TEMPLATE_URL && (
        <a
          href={COMPANIES_BULK_IMPORT_TEMPLATE_URL}
          download
          target="_blank"
          rel="noreferrer"
        >
          Companies Excel Load Template
        </a>
      )}

      {data && data.added > 1 ? (
        <Alert
          type="success"
          messages={[
            t('loadCompaniesFromExcelSuccess', {
              added: data.added,
              total: data.total,
            }),
          ]}
        />
      ) : undefined}

      {data && data.errors.length > 0 ? (
        <Alert type="error" messages={data.errors} />
      ) : undefined}

      <Container>
        <DraggableProvider>
          <LoadCompaniesToProjectDragDrop
            onDrop={handleDroppedFiles}
            isLoading={isLoading}
          />
        </DraggableProvider>
      </Container>
    </Stack>
  );
};

const Container = styled('div')`
  position: relative;
  height: 300px;
  margin: 14px 0;
  align-content: center;
`;
