import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchProjectCompanies } from '@/api/v4/projects.api';
import { CommentsTab } from '@/features/action-panel/comments/comments-tab';
import { modalState } from '@/state/modal.state';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { projectIdState } from '../../project.state';

import { useProjectComments } from './use-project-comments.query';
import { useProjectCompanies } from '../../use-project-companies.query';
import { useCreateProjectComment } from './use-create-project-comment.mutation';
import { useUpdateProjectComment } from './use-update-project-comment.mutation';
import { useDeleteProjectComment } from './use-delete-project-comment.mutation';

export const ProjectCommentsTab = () => {
  const [refCommentId, setRefCommentId] = useState<string | null>(null);
  const projectId = useRecoilValue(projectIdState);
  const setModal = useSetRecoilState(modalState);

  const { data: projectCompanies, isFetched } = useProjectCompanies(projectId);
  const { data: comments, refetch } = useProjectComments();

  const { createComment } = useCreateProjectComment();
  const { updateComment, isUpdateInProgress } = useUpdateProjectComment();
  const { deleteComment: deleteProjectComment, isDeleteInProgress } =
    useDeleteProjectComment();

  if (!projectId) return null;

  const submitComment = async (comment: string, mentionedUsers: number[]) => {
    const data = await createComment({
      comment,
      projectId,
      mentionedUsers,
    });

    setRefCommentId(data.id);
    await refetch();
  };

  const openDeleteModal = async (commentId: string) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: () => deleteComment(commentId),
    });
  };

  const deleteComment = async (commentId: string) => {
    await deleteProjectComment(commentId);
    await refetch();
  };

  const editComment = async (
    commentId: string,
    data: {
      comment: string;
      projectListingId?: string | null;
      mentionedUsers: number[];
    },
  ) => {
    setRefCommentId(commentId);
    await updateComment({
      commentId,
      comment: data.comment,
      ...(data.projectListingId !== undefined && {
        projectListingId: data.projectListingId,
      }),
      mentionedUsers: data.mentionedUsers,
    });

    await refetch();
  };

  const setOptions: CustomAsyncSelectProps['setOptions'] = async search => {
    const response = await fetchProjectCompanies(projectId, {
      search,
    });

    return response.map(item => ({
      value: item.projectListingId,
      label: item.name,
      logo: item.logoUrl,
      domain: item.domain,
      organizationId: item.id,
      projectListingId: item.projectListingId,
    }));
  };

  return (
    <CommentsTab
      onSubmit={submitComment}
      comments={comments ?? []}
      onClickDelete={openDeleteModal}
      onEditSubmit={editComment}
      refCommentId={refCommentId}
      resetCommentId={() => setRefCommentId(null)}
      loading={isUpdateInProgress || isDeleteInProgress}
      dropdown={{
        setOptions,
        isDisabled: isFetched && !projectCompanies?.length,
      }}
    />
  );
};
