import { styled } from 'goober';

interface AlertProps {
  type: 'success' | 'error'; // Define acceptable types
  messages: string[]; // Array of messages to display
  onClose?: () => void; // Function to call when the alert is closed
}

export const Alert = ({ type, messages }: AlertProps) => {
  return (
    <AlertContainer type={type}>
      {messages.map((message, index) => (
        <AlertMessage key={index}>- {message}</AlertMessage>
      ))}
    </AlertContainer>
  );
};

const AlertContainer = styled('div')<Pick<AlertProps, 'type'>>`
  padding: 16px;
  margin: 16px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column; // Change to column to support multiple messages
  cursor: pointer;
  transition: opacity 0.3s ease;
  background-color: ${props =>
    props.type === 'success'
      ? props.theme.colors.blue.primaryA
      : props.theme.colors.system.lowFit};
  color: ${props => props.theme.colors.basics.white};
`;

const AlertMessage = styled('span')`
  flex-grow: 1;
  margin-bottom: 6px; // Add spacing between messages
`;
